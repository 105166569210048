<script>
    import { onMount } from "svelte";
    // import { handleOffscreenClass } from "../scripts/isOffScreen.svelte";
    import Nav from "./parts/Nav.svelte";

    export let big = false;
    export let reorder_m = false;

    export let left_heading;
    export let left_text;
    export let left_bg_min_height;
    export let left_nav = [];
    export let left_bg_img;
    export let left_bg_col;
    export let left_dark_scheme = false;
    let left_scheme = (left_dark_scheme) ? "dark" : "light";
    export let left_bg_contain = false;
    export let left_hide_m = false;

    export let right_heading;
    export let right_text;
    export let right_bg_min_height;
    export let right_nav = [];
    export let right_bg_img;
    export let right_bg_col;
    export let right_dark_scheme = false;
    let right_scheme = (right_dark_scheme) ? "dark" : "light";
    export let right_bg_contain = false;
    export let right_hide_m = false;

    // let section;
    // onMount(()=>{
    //     handleOffscreenClass(section);
    // })
</script>

<!-- <svelte:window
    on:scroll={handleOffscreenClass(section)}
    on:resize={handleOffscreenClass(section)}
/> -->

<section class={"slide" + ((reorder_m) ? " reorder_m" : "")}>
    <div class={"left " + left_scheme + (left_hide_m ? " hide_m" : "")} style={
        ((left_bg_col) ? "background-color: " + left_bg_col + ";" : "") +
        ((left_bg_img && left_bg_img.path) ? "background-image: url(" + 'https://www.saintdust.com' + left_bg_img.path + ");" : "") +
        ((left_bg_contain) ? "background-size: contain;" : "") +
        ((big) ? "min-height: 70vh;" : "min-height: 30vh;") +
        ((left_bg_min_height && left_bg_img) ? "min-height: " + left_bg_min_height + "vh;" : "")
    }>
        {#if left_heading}<h1>{@html left_heading}</h1>{/if}
        {#if left_text}<p>{@html left_text}</p>{/if}
        {#if left_nav && left_nav.length}<Nav nav={left_nav} />{/if}
    </div>
    <div class={"right " + right_scheme + (right_hide_m ? " hide_m" : "")} style={
        ((big) ? "min-height: 70vh;" : "min-height: 30vh;") +
        ((right_bg_min_height && right_bg_img) ? "min-height: " + right_bg_min_height + "vh;" : "")
    }>
        {#if right_heading}<h1>{@html right_heading}</h1>{/if}
        {#if right_text}<p>{@html right_text}</p>{/if}
        {#if right_nav && right_nav.length}<Nav nav={right_nav} />{/if}

        <div class={"under " + right_scheme} style={
            ((right_bg_col) ? "background-color: " + right_bg_col + ";" : "") +
            ((right_bg_img && right_bg_img.path) ? "background-image: url(" + 'https://www.saintdust.com' + right_bg_img.path + ");" : "") +
            ((right_bg_contain) ? "background-size: contain;" : "") +
            ((big) ? "min-height: 70vh;" : "min-height: 30vh;") +
            ((right_bg_min_height && right_bg_img) ? "min-height: " + right_bg_min_height + "vh;" : "")
        }></div>
    </div>
</section>

<style lang="scss" global>:global(section.slide) {
  display: flex;
  flex-flow: row;
  position: relative;
  box-sizing: border-box;
  justify-content: space-around;
  overflow: hidden;
  width: 100%;
}
:global(section.slide) :global(.left),
:global(section.slide) :global(.right),
:global(section.slide) :global(.under) {
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-flow: column;
  justify-content: center;
  min-height: 30vh;
  width: 50%;
  padding: 2em 4em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: black;
}
:global(section.slide) :global(.left.dark),
:global(section.slide) :global(.right.dark),
:global(section.slide) :global(.under.dark) {
  color: white;
}
@media (max-width: 1200px) {
  :global(section.slide) {
    flex-flow: column;
  }
  :global(section.slide.reorder_m) :global(.right) {
    order: -1;
  }
  :global(section.slide) :global(.hide_m) {
    display: none;
  }
}
:global(section.slide) :global(.left) {
  padding-left: 15%;
  z-index: 10;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, calc(100% - 50px) 100%, 0 100%);
  background-color: #f4f4f4;
}
:global(section.slide) :global(.left.dark) {
  background-color: black;
}
:global(section.slide) :global(.right) {
  padding-right: 15%;
  background-color: transparent;
  overflow: visible;
  z-index: 9;
}
:global(section.slide) :global(.right) :global(.under) {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  left: -50px;
  width: calc(100% + 50px);
  z-index: -1;
}
:global(section.slide) :global(.right) :global(.under.dark) {
  background-color: black;
}
@media (max-width: 1200px) {
  :global(section.slide) :global(.right) :global(.under) {
    padding: 2em;
    width: 100%;
    left: 0;
  }
}
:global(section.slide.offscreen) :global(.left) :global(h1), :global(section.slide.offscreen) :global(.left) :global(p), :global(section.slide.offscreen) :global(.left) :global(nav), :global(section.slide.offscreen) :global(.right) :global(h1), :global(section.slide.offscreen) :global(.right) :global(p), :global(section.slide.offscreen) :global(.right) :global(nav) {
  animation-name: fadeout;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
}
:global(section.slide) :global(.left) :global(h1), :global(section.slide) :global(.left) :global(p), :global(section.slide) :global(.left) :global(nav), :global(section.slide) :global(.right) :global(h1), :global(section.slide) :global(.right) :global(p), :global(section.slide) :global(.right) :global(nav) {
  animation-name: slideinup;
  animation-duration: 0.75s;
}
@media (max-width: 1500px) {
  :global(section.slide) :global(.left),
  :global(section.slide) :global(.right) {
    width: 100%;
    padding: 2em;
  }
}
@media (max-width: 1200px) {
  :global(section.slide) :global(.left),
  :global(section.slide) :global(.right) {
    clip-path: none;
  }
}
:global(section.slide) :global(h1) {
  font-size: 3em;
}
@media (max-width: 1200px) {
  :global(section.slide) :global(h1) {
    font-size: 2em;
  }
}</style>