<script>
    import { startSlideshow, animate } from "../scripts/Slideshow.svelte";
    import { onMount } from "svelte";

    export let bubbles = [];
    export let dark_scheme = false;
    let scheme = dark_scheme ? "dark" : "light";
    
    function generateFaStars(count) {
        let html = "";
        for(let i = 0; i < count; i++) {
            html += '<i class="fa fa-star"></i>';
        }
        for(let i = count; i < 5; i++) {
            html += '<i class="far fa-star"></i>';
        }
        return html;
    }


    let slides;
    let startTimer;
    let isPaused = false;
    let stopSlideshow = ()=>{};
    let slideshowGetCurrent = ()=>{return undefined};

    function moveSlide(previous=false) {
        if (slides && slides.children) {
            var current = slideshowGetCurrent();
            stopSlideshowAndTimer();
            animate(slides.children,current,previous,true,(next)=>{
                slideshowGetCurrent = ()=>{return next;}
            })
        }
    }

    function stopSlideshowAndTimer() {
        stopSlideshow();
        isPaused = true;
        if (startTimer) clearTimeout(startTimer);
    }
    
    function continueSlideshowTimer() {
        isPaused = false;
        startTimer = setTimeout(()=>{
            stopSlideshow();
            if (slides && slides.children) [slideshowGetCurrent, stopSlideshow] = startSlideshow(slides.children,slideshowGetCurrent());
        }, 2500)
    }

    onMount(()=>{
        if (slides && slides.children) [slideshowGetCurrent, stopSlideshow] = startSlideshow(slides.children);
        return ()=> {stopSlideshow();}
    })
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<section class={"bubbles " + scheme} on:mouseover={stopSlideshowAndTimer} on:mouseout={continueSlideshowTimer}>
    {#if bubbles && bubbles.length}
        <div class="slides" bind:this={slides}>
        {#each bubbles as slide}
        {#if slide.value}
            <div class="slide hidden">
                {#if slide.value.text}<p>{@html slide.value.text}</p>{/if}
                <!-- <div class="bottom"> -->
                    {#if slide.value.author}<div class="author">~ {slide.value.author}</div>{/if}
                    <!-- {#if slide.value.stars}<div class="stars">{@html generateFaStars(slide.value.stars)}</div>{/if} -->
                <!-- </div> -->
            </div>
        {/if}
        {/each}
        </div>
        {#if isPaused}<div class="paused" aria-label="Slideshow is currently paused"><i class="fa fa-pause"></i></div>{/if}
        <button class="navigate prev" on:click={()=>{moveSlide(true)}} aria-label="Previous slide"><i class="fa fa-angle-double-left"></i></button>
        <button class="navigate next" on:click={()=>{moveSlide()}} aria-label="Next slide"><i class="fa fa-angle-double-right"></i></button>
    {/if}
</section>

<style lang="scss" global>:global(section.bubbles) {
  height: 250px;
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: white;
  color: black;
}
:global(section.bubbles) :global(.slide) {
  position: relative;
  border: 1px solid lightgray;
  padding: 25px 40px;
  min-width: 200px;
  max-width: 900px;
}
:global(section.bubbles) :global(.slide) :global(.bottom) {
  display: flex;
  min-height: 30px;
  justify-content: flex-end;
}
:global(section.bubbles) :global(.slide) :global(.author) {
  text-align: right;
  font-weight: bold;
  font-style: italic;
}
:global(section.bubbles) :global(button.navigate) {
  position: absolute;
  background: white;
  border: 1px solid lightgray;
  color: #0083FF;
  margin: 20px;
  width: 50px;
  height: 50px;
  padding: 0;
  align-items: center;
  border-radius: 50%;
}
:global(section.bubbles) :global(button.navigate.prev) {
  left: calc(50vw - 600px);
}
:global(section.bubbles) :global(button.navigate.next) {
  right: calc(50vw - 600px);
}
@media (max-width: 1200px) {
  :global(section.bubbles) :global(button.navigate.prev) {
    left: 0px;
  }
  :global(section.bubbles) :global(button.navigate.next) {
    left: auto;
    right: 0px;
  }
}
@media (max-width: 600px) {
  :global(section.bubbles) :global(button.navigate) {
    width: 40px;
    height: 40px;
    margin: 5px;
  }
}
:global(section.bubbles.dark) {
  color: white;
  background: black;
}
:global(section.bubbles.dark) :global(.slide) {
  border: 1px solid rgb(66, 66, 66);
}
:global(section.bubbles.dark) :global(.stars) {
  background: black;
}
:global(section.bubbles.dark) :global(button.navigate) {
  border: 1px solid rgb(66, 66, 66);
  background: #262626;
}
:global(section.bubbles) :global(div.paused) {
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  opacity: 0.5;
  display: flex;
  justify-content: center;
}
:global(section.bubbles) :global(.hidden) {
  display: none;
}
:global(section.bubbles) :global(.animate-in) {
  animation-name: slidein;
  animation-duration: 1s;
}
@keyframes -global-slidein {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
:global(section.bubbles) :global(.animate-out) {
  animation-name: slideout;
  animation-duration: 1s;
}
@keyframes -global-slideout {
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}</style>