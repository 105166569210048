<script>
    import { onMount } from 'svelte';

    export let imgs=[];
    export let dark_scheme = false;
    export let preview = false;
    let scheme = dark_scheme ? "dark" : "light";
    export let bg="";
    
    // External JS management
    onMount(()=>{
        // Check head scripts if it hasn't been appended already
        if(Array.prototype.slice.call(document.head.getElementsByTagName("script")).filter(sc => {return sc.src.includes("/js/refsAutoScroll.js")}).length === 0) {
            // Append script
            let sc = document.createElement("script");
            sc.src = "/js/refsAutoScroll.js?v=4"
            document.head.appendChild(sc);
            return ()=>{ // onDestroy
                // Remove script
                try{clearInterval(refInterval);}catch{}
                document.head.removeChild(sc);
            }
        }
    });
</script>

<section class={"ref " + scheme + (preview ? " preview" : "")} style={(bg) ? "background:" + bg + ";" : ""}>
{#if imgs && imgs.length}
    <div class="content">
    {#each imgs as i}
        <img    
            src={'https://www.saintdust.com' + i.path} 
            alt={(i.meta && i.meta.title) ? i.meta.title : "Reference / Referencia"} 
            style={
                ((i.meta && i.meta.width) ? "width: " + i.meta.width + "px;" : "") + 
                ((i.meta && i.meta.height) ? "height: " + i.meta.height + "px;" : "")
            }
        >
    {/each}
    </div>
{/if}
</section>

<style lang="scss" global>:global(section.ref) {
  display: flex;
  position: relative;
  flex-flow: row;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: lightgray;
  padding: 2em 0;
  justify-content: center;
}
:global(section.ref.animate) {
  justify-content: flex-start;
}
:global(section.ref.dark) {
  background-color: rgb(49, 49, 49);
}
:global(section.ref.preview:hover) :global(img) {
  border: 1px solid red;
}
:global(section.ref) :global(img) {
  position: relative;
  height: 70px;
  width: 150px;
  object-fit: contain;
  margin: 1em;
}
:global(section.ref) :global(img) :global(:first-of-type) {
  margin-left: 0;
}
:global(section.ref) :global(.content) {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  flex-shrink: 0;
  align-items: center;
}</style>