<script>
    import { onMount } from "svelte";
    import { handleOffscreenClass } from "../scripts/isOffScreen.svelte";
    import Nav from "./parts/Nav.svelte";
    import Video from "../sections/Video.svelte";

    export let dark_scheme = false;
    let scheme = dark_scheme ? "dark" : "light";
    export let bg_color = dark_scheme ? "black" : "white";
    export let bg_img = "";
    export let bg_vid = "";
    export let bg_vid_opacity = "";
    export let bg_img_tx = "";
    export let bg_img_m = "";
    export let bg_cover = false;
    export let heading = "";
    export let heading_size = "normal";
    export let text = "";
    export let align = "left";
    export let min_height;
    export let nav = [];

    let section;
    onMount(()=>{
        handleOffscreenClass(section);
    })
</script>

<svelte:window
    on:scroll={handleOffscreenClass(section)}
    on:resize={handleOffscreenClass(section)}
/>

<section class={"titulka " + scheme} bind:this={section} style={
        ((bg_color) ? "background-color:" + bg_color + ";" : "") + 
        ((bg_img_tx && bg_img_tx.path) ? "background-image: url(" + 'https://www.saintdust.com' + bg_img_tx.path + "); background-position: center;" : "") + 
        ((min_height) ? "min-height:"+min_height+"vh;" : "")
    }>
    {#if bg_img && bg_img.path || bg_img_m && bg_img_m.path || bg_vid && bg_vid.path}
    <div class={"pic" + ((bg_cover || bg_vid) ? " cover" : "")}>
        <!-- svelte-ignore a11y-img-redundant-alt -->
        {#if bg_img && bg_img.path}<img class={(bg_img_m && bg_img_m.path ? "hide_m" : "")} src={bg_img && 'https://www.saintdust.com' + bg_img.path} alt="Title photo / Titulné foto">{/if}
        <!-- svelte-ignore a11y-img-redundant-alt -->
        {#if bg_img_m && bg_img_m.path}<img class="hide_d" src={bg_img_m && 'https://www.saintdust.com' + bg_img_m.path} alt="Mobile title photo / Mobilné titulné foto">{/if}
        {#if bg_vid && bg_vid.path}<Video local={bg_vid} theme="fullwidth autoplay embedded" loopopacity={bg_vid_opacity} />{/if}
    </div>
    {/if}
    <div class={"content " + align}>
        {#if heading}
            <h1 class={(heading_size == "big") ? "big" : "normal"}>
                {@html (align === "left" && typeof heading === "string") ? heading.replace(/\s/g,"<br>") : heading}
            </h1>
        {/if}
        {#if text}<p>{@html text}</p>{/if}
        {#if nav && nav.length}
            <Nav nav={nav} />
        {/if}
    </div>
</section>

<style lang="scss" global>:global(section.titulka) {
  display: flex;
  position: relative;
  flex-flow: column;
  justify-content: flex-end;
  box-sizing: border-box;
  background-position: center;
  padding: 2em;
  width: 100%;
  background: #f2f2f2;
  color: black;
  overflow: hidden;
}
:global(section.titulka) :global(.pic) {
  display: flex;
  width: 100%;
  top: 15px;
  left: 0;
  height: 100%;
  justify-content: center;
  position: absolute;
  bottom: 0;
}
@media (max-width: 1200px) {
  :global(section.titulka) :global(.pic) {
    top: 0;
  }
}
@media (max-width: 800px) {
  :global(section.titulka) :global(.pic) :global(.hide_m) {
    display: none;
  }
}
@media (min-width: 801px) {
  :global(section.titulka) :global(.pic) :global(.hide_d) {
    display: none;
  }
}
:global(section.titulka) :global(.pic) :global(img) {
  height: 100%;
  max-width: 90%;
  object-fit: contain;
}
@media (max-width: 1200px) {
  :global(section.titulka) :global(.pic) :global(img) {
    object-fit: cover;
    max-width: 100%;
  }
}
:global(section.titulka) :global(.pic.cover) {
  top: 0;
}
:global(section.titulka) :global(.pic.cover) :global(img) {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}
:global(section.titulka.offscreen) :global(.content) {
  animation-name: fadeout;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
}
:global(section.titulka) :global(.content) {
  animation-name: slideinup;
  animation-duration: 0.75s;
  display: flex;
  flex-flow: column;
  position: relative;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  left: 0;
  z-index: 99;
  padding: 0 15%;
  height: auto;
  box-sizing: border-box;
  justify-content: space-around;
}
@media (max-width: 1900px) {
  :global(section.titulka) :global(.content) {
    padding: 0 10%;
  }
}
@media (max-width: 1200px) {
  :global(section.titulka) :global(.content) {
    padding: 0;
  }
}
:global(section.titulka) :global(.content.left) {
  align-items: flex-start;
}
:global(section.titulka) :global(.content.center) {
  align-items: center;
}
:global(section.titulka) :global(.content.center) :global(h1) {
  text-align: center;
}
:global(section.titulka) :global(.content.right) {
  align-items: flex-end;
}
:global(section.titulka) :global(.content.right) :global(h1) {
  text-align: right;
}
:global(section.titulka) :global(.content) :global(span) {
  margin-bottom: 15px;
}
@media (max-width: 700px) {
  :global(section.titulka) :global(.content) :global(p) {
    text-align: center;
    align-self: center;
  }
}

:global(.titulka.dark) {
  color: white;
  background: black;
}</style>