<script>
    import { onMount } from 'svelte';
    import { handleOffscreenClass } from '../scripts/isOffScreen.svelte';

    handleOffscreenClass

    export let dark_scheme = false;
    let scheme = dark_scheme ? "dark" : "light";
    export let bg_img = "";
    export let ranks = [];

    let section;
    onMount(()=>{
        handleOffscreenClass(section);
    })
</script>

<svelte:window 
    on:scroll={handleOffscreenClass(section)}
    on:resize={handleOffscreenClass(section)}
/>

<section class={"ranking " + scheme} style={(bg_img && bg_img.path) ? "background-image: url('"+'https://www.saintdust.com'+bg_img.path+"');" : ""} bind:this={section}>
{#if ranks && ranks.length}
    <ul class="content">
        {#each ranks as r}
            {#if r.value}
            <li>
                <h1 class="count">{@html (r.value.heading) ? r.value.heading : ""}</h1>
                <span class="name">{(r.value.name) ? r.value.name : ""}</span>
            </li>
            {/if}
        {/each}
    </ul>
{/if}
</section>

<style lang="scss" global>:global(section.ranking) {
  display: flex;
  flex-flow: row;
  width: 100%;
  padding: 2em;
  box-sizing: border-box;
  color: black;
  background-color: lightgray;
  background-position: center;
  background-size: cover;
}
:global(section.ranking.dark) {
  color: white;
  background-color: black;
}
:global(section.ranking.dark) :global(h1) {
  -webkit-text-stroke-color: white;
}
:global(section.ranking.dark) :global(h1) :global(b) {
  -webkit-text-fill-color: white;
}
:global(section.ranking.offscreen) :global(.content) {
  animation-name: fadeout;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
}
:global(section.ranking) :global(.content) {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-around;
  border: none;
  animation-name: scalein;
  animation-duration: 0.75s;
}
:global(section.ranking) :global(.content) :global(li) {
  border: none;
  padding: 1em;
  display: flex;
  flex-flow: column;
  text-transform: uppercase;
  align-items: center;
}
:global(section.ranking) :global(.content) :global(li) :global(.count) {
  font-size: 3em;
}
@media (max-width: 500px) {
  :global(section.ranking) :global(.content) :global(li) :global(.count) {
    font-size: 2.5em;
  }
}

:global(body.theme-goldshow) :global(.ranking) :global(.content) :global(.count) {
  font-size: 4em;
}
@media (max-width: 500px) {
  :global(body.theme-goldshow) :global(.ranking) :global(.content) :global(.count) {
    font-size: 3.5em;
  }
}</style>