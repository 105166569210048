<script>
	import { beforeUpdate, afterUpdate } from "svelte";

	import axios from "axios";
	import Router from "./routes/Router.svelte";
	import { lang } from "./routes/LanguageStore.svelte";
	import { acnt } from "./routes/AccentStore.svelte";
	import Preview from "./routes/Preview.svelte";
	import { beforePreviewUpdate, afterPreviewUpdate } from "./routes/Preview.svelte";
	// import Strukshop from "./Strukshop.svelte";

	import Body from "./parts/Body.svelte";
	import Head from "./parts/Head.svelte";
    import Gdpr from "./parts/GDPR.svelte";
	import GlobalScripts from "./parts/GlobalScripts.svelte";
	import Loading from "./parts/Loading.svelte";
	import Theme from "./parts/Theme.svelte";
	import Header from "./parts/Header.svelte";
	import Layout from "./parts/Layout.svelte";
	import Modal from "./parts/Modal.svelte";
	import Footer from "./parts/Footer.svelte";

	// import Strukshop from "./Strukshop.svelte";

	// Clear static body content
	document.body.innerHTML = "";

    // Site name selection (StrukShow/Brian Struk)
    let isBrian = (window.location.hostname.indexOf("brianstruk") < 0) ? false : true;
    let isLuba = (window.location.hostname.indexOf("lubastrukova") < 0) ? false : true;
    let isStruk = (window.location.hostname.indexOf("strukshow") < 0) ? false : true;
    let siteName = (isBrian) ? "Brian Struk" : ((isLuba) ? "Luba Strukova" : ((isStruk) ? "StrukShow" : "SaintDust"));
    let gdprResetBinding;

	// Language detection
	let language;
	lang.setSupported((isBrian) ? ["en"] : ((isLuba) ? ["sk"] : ((isStruk) ? ["en", "sk"] : ["en"])));
	lang.subscribe(l => language = l.current);

    // Accent default values
    acnt.setDefaultColor("rgb(0,131,255)"); // default accent color, use rgb instead of hex for svg dropdown in langselector
    // this matches $color-link, although that one will be mostly overwritten by this now

	// Preview mode
	let previewPageContent;
	let previewPageContentCached = {layout: undefined, theme: undefined};
	function previewScrollBinding() {
		beforeUpdate(beforePreviewUpdate);
		afterUpdate(afterPreviewUpdate);
		return true;
	}
	
	// Cockpit API
	const api = new function() {
		this.cockpit = window.location.protocol + "//" + "www.saintdust.com" + "/cockpit/api/";
		this.custom =  window.location.protocol + "//" + "www.saintdust.com" + "/api/";
		this.pages = (isBrian) ? this.cockpit + "collections/get/PageBrian" : ((isLuba) ? this.cockpit + "collections/get/PageLuba" : ((isStruk) ? this.cockpit + "collections/get/Page" : this.cockpit + "collections/get/PageSaint"));
		this.meta = (isBrian) ? this.cockpit + "singletons/get/SiteSettingsBrian" : ((isLuba) ? this.cockpit + "singletons/get/SiteSettingsLuba" : ((isStruk) ? this.cockpit + "singletons/get/SiteSettings" : this.cockpit + "singletons/get/SiteSettingsSaint"));
		this.counter = this.custom + "set.php"
	}
	function apiSuffix(lang) {
		return "?lang=" + lang + "&cache=" + Date.now();
	}

	// Slug; Content management after retrieval
	let slug = window.location.pathname;
	async function assignContent(slug, lang, content){
		for(let i = 0; i < content.length; i++) {
			if (slug === "/" + lang + "/" + content[i].slug) {return content[i];}
		}
		throw new Error("Slug content not found"); 
	}
	function slugIs(slug,subsite) {
		return ((slug+"/").indexOf("/" + subsite + "/") === 0)
	}

	// Custom basic Analytics implementation
	// let prevSlug;
	// let stamp = Date.now();
	// function updateCounter(previousSlug,newSlug,counter,link) {
	// 	axios.post(api.counter,{
	// 		current:previousSlug,
	// 		new:newSlug,
	// 		id:counter,
	// 		link:link
	// 	}).catch(error => {});
	// }
	function climbToSection(elm) {
		while (elm.parentElement && !["section","SECTION","header","HEADER","footer","FOOTER"].includes(elm.parentElement.tagName)) {
			elm = elm.parentElement;
		}
		if (elm.parentElement) elm = elm.parentElement;
		return (elm.parentElement) ? elm : undefined;
	}

	// Dev
	// function show(object) {
	// 	return JSON.stringify(object,undefined,2);
	// }
</script>

<!-- GLOBAL SCRIPTS -->
<GlobalScripts />

<!-- ROUTER -->
<Router 
	bind:fullslug={slug} 
	excludedSlugs={["/preview"]} 
	excludedPrefixSlugs={["/shop"]} 
	beforeReroute={()=>{
        // prevSlug = slug
    }} 
	afterReroute={(l)=>{
		// if (l) {
		// 	if (typeof l === 'string') {
		// 		updateCounter(prevSlug,slug,Date.now() - stamp,[l]);
		// 	} else {
		// 		let s = climbToSection(l);
		// 		updateCounter(prevSlug,slug,Date.now() - stamp,
		// 		[l.tagName, l.innerText || l.className,
		// 		s ? s.tagName : "", s ? s.className : ""]);
		// 	}
		// }
		// stamp = Date.now();
	}} 
/>

<!-- ABSOLUTE SLUGS (outside of Cockpit) -->
{#if slugIs(slug,"preview") && previewScrollBinding()}
	<Preview bind:receivedMessage={previewPageContent} />
	{#if previewPageContent}
		<!-- Show preview content -->
		<Head title={"Preview - " + siteName} index={false} />
		<Body classes={["preview"]} />
		{#if previewPageContent.layout && previewPageContent.layout.length}
			<Theme changeTo={previewPageContentCached.theme = previewPageContent.theme} />
			<Layout layout={previewPageContentCached.layout = previewPageContent.layout} preview={true} />
		{:else if previewPageContentCached && previewPageContentCached.layout && previewPageContentCached.theme}
			<Theme changeTo={previewPageContentCached.theme} />
			<Layout layout={previewPageContentCached.layout} preview={true} />
		{:else}
			<Loading fullscreen />
		{/if}
	{:else}
		<!-- Loading / Access denied -->
		<Head title={siteName} index={false}/>
		<Loading fullscreen />
	{/if}
<!-- {:else if slugIs(slug,"shop")} -->
	<!-- <Strukshop apiHost={api.custom} subpath="/shop" bind:slug={slug}/>  -->
{:else}

<!-- COCKPIT SLUGS -->
{#await 
	axios.all([
		// Retrieve all relevant APIs
		axios.get(api.meta + apiSuffix(language)),
		axios.get(api.pages + apiSuffix(language))
	]).then(res => {return { 
		// Join Metadata with Pages into a single object
		meta: {...res[0]},
		...res[1]
	}})
}
	<!-- Loading Cockpit Page content -->
	<Head title={siteName} />
	<Loading fullscreen />
{:then res}
	{#await assignContent(slug,language,res.data.entries)}
		<!-- Assigning Page content -->
		<Head title={siteName} desc={res.meta.data.description || ""} extra={res.meta.data.extra || ""} />
        <Gdpr scripts={res.meta.data.scripts || []} providers={res.meta.data.providers || ""} bind:reset={gdprResetBinding}/>
		<Loading fullscreen />
	{:then page}
		<!-- Page loaded -> LAYOUT GENERATION -->
		<Head 
			title={(page.name ? page.name + " - " : "") + siteName}
			desc={page.description || res.meta.data.description || ""}
            extra={res.meta.data.extra || ""}
		/>
        <Gdpr scripts={res.meta.data.scripts || []} providers={res.meta.data.providers || ""} bind:reset={gdprResetBinding}/>
		<Theme changeTo={page.theme} accentTo={page.accent} />
		<Header 
			logo={page.dark_scheme ? res.meta.data.logo_white : res.meta.data.logo_black}
			navPages={
				// [...res.data.entries, {name:"StrukShop", slug:"shop", showAs:"button", nav:{display:"header"}}]
				res.data.entries
			} 
			navSocial={res.meta.data.social}
			filterNav="header"
			hideNav={!page.show_header_nav}
			mottosOnHideNav={res.meta.data.mottos}
			dark_scheme={page.dark_scheme}
			addMargin={true}
		/>
		<Layout layout={page.layout} />
		<Modal />
		<Footer 
			navPages={res.data.entries}
			filterNav="footer"
			credits={res.meta.data.credits}
			dark_scheme={page.dark_scheme}
            gdprReset={gdprResetBinding}
		/>
	{:catch}
		<!-- Slug not found in Cockpit -->
		<Head title={"404 " + (language === "sk" ? "Stránka neexistuje" : "Not Found") + " - " + siteName} index={false} extra={res.meta.data.extra || ""}/>
        <Gdpr scripts={res.meta.data.scripts || []} providers={res.meta.data.providers || ""} bind:reset={gdprResetBinding}/>
		<Header 
			logo={res.meta.data.logo_black}
			navPages={res.data.entries} 
			filterNav="header"
		/>
		<section class="fullscreen">
			{#if language === "sk"}
				<Layout layout={[{
					component:"titulka",
					settings:{
						heading:"<b>404</b> Stránka neexistuje",
						text:"Pravdepodobne ju niekto odčaroval.",
						bg_img:{path:"/img/fillers/error_bg.jpg"},
						nav:[{value:{
							icon: "fa fa-arrow-left",
							name: "Naspäť domov",
							href: "/",
							button:true
						}}]}}]} />
			{:else}
			<Layout layout={[{
				component:"titulka",
				settings:{
					heading:"<b>404</b> Not found",
					text:"This page was likely <i>struk</i> by lightning.",
					bg_img:{path:"/img/fillers/error_bg.jpg"},
					nav:[{value:{
						icon: "fa fa-arrow-left",
						name: "Go back",
						href: "/",
						button:true
					}}]}}]} />
			{/if}
		</section>
	{/await}
{:catch}
	<!-- Cockpit unreachable -->
	<Head title={"500 " + (language === "sk" ? "Načítanie zlyhalo" : "Loading Failed") + " - " + siteName} index={false}/>
	<Header 
		showArrowNoLogo={false}
	/>
	<section class="fullscreen">
		{#if language === "sk"}
			<Layout layout={[{
				component:"titulka",
				settings:{
					heading:"<b>500</b> Načítavanie zlyhalo",
					text:"Ospravedlňujeme sa za obtiažnosti, skúste si stránku pozrieť neskôr.",
					bg_img:{path:"/img/fillers/error_bg.jpg"}
				}}]} />
		{:else}
			<Layout layout={[{
				component:"titulka",
				settings:{
					heading:"<b>500</b> Loading failed",
					text:"We apologize for the inconvenience, please try again later.",
					bg_img:{path:"/img/fillers/error_bg.jpg"}
				}}]} />
		{/if}
	</section>
{/await}
{/if}

<style lang="scss" global>@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");
@font-face {
  font-family: "Leyton Bold";
  src: url("/font/Leyton-Ext-Bold.otf");
}
@keyframes -global-slideinup {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes -global-slideindown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes -global-slideoutdown {
  from {
    transform: translateY(0%);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
@keyframes -global-fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes -global-semifadein {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
@keyframes -global-semifadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}
@keyframes -global-slideinright {
  from {
    transform: translateX(-50vw);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes -global-slideoutright {
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(50vw);
    opacity: 0;
  }
}
@keyframes -global-scalein {
  from {
    transform: scale(1.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
:global(html), :global(body) {
  position: relative;
  overflow-x: hidden;
}

:global(body) {
  font-size: 20px;
  color: black;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
:global(body), :global(body) :global(button) {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}
@media (max-width: 550px) {
  :global(body) {
    font-size: 16px;
  }
}

:global(pre) {
  white-space: pre-wrap;
}

:global(.dark) {
  color: white;
  background: black;
}

:global(.spinner) {
  width: 70px;
}

:global(select) {
  color: #0083FF;
}

:global(a) {
  color: rgb(0, 104.8, 204);
  text-decoration: none;
}
:global(a.disabled) {
  cursor: default;
  filter: saturate(0);
}
:global(a:hover) {
  text-decoration: underline;
}
:global(a:hover.disabled) {
  text-decoration: none;
}
:global(a) :global(svg) {
  margin-right: 10px;
}

:global(b), :global(strong) {
  font-weight: bold;
}

:global(.button), :global(button) {
  outline: none;
  cursor: pointer;
  padding: 10px 25px;
  font-size: 20px;
  background: rgb(0, 104.8, 204);
  color: white;
  border: none;
  text-decoration: none;
}
:global(.button:not(.disabled):hover), :global(button:not(.disabled):hover) {
  background: rgb(25.5, 143.4, 255);
  text-decoration: underline;
}
:global(.button:not(.disabled):focus), :global(button:not(.disabled):focus) {
  box-shadow: 0 0 0 2px inset rgba(255, 255, 255, 0.2);
}
@media (max-width: 550px) {
  :global(.button), :global(button) {
    font-size: 16px;
  }
}

:global(.button-link) {
  color: rgb(0, 104.8, 204);
  text-decoration: none;
}
:global(.button-link), :global(.button-link:not(.disabled):hover), :global(.button-link:not(.disabled):focus) {
  color: #0083FF;
  background: transparent;
}
:global(.button-link:not(.disabled):hover) {
  text-decoration: underline;
}

:global(.message) {
  display: block;
  flex-direction: row;
  box-sizing: border-box;
  flex-grow: 1;
  padding: 15px;
  margin: 15px;
  max-width: calc(100% - 30px);
  background: white;
}
:global(.message.info) {
  border: 1px solid rgb(236, 174, 81);
  color: rgb(236, 174, 81);
}
:global(.message.warn) {
  background: rgb(236, 174, 81);
}
:global(.message.error) {
  background: rgb(236, 90, 90);
}

:global(h1) {
  font-family: "Archivo Black", sans-serif;
  font-size: 4em;
  line-height: 1em;
  margin: 0;
  margin-bottom: 15px;
  text-transform: uppercase;
  -webkit-text-stroke: 1.5px black;
  -webkit-text-fill-color: transparent;
}
:global(h1.big) {
  font-size: 7em;
}
:global(h1) :global(b) {
  -webkit-text-stroke: 0px;
  -webkit-text-fill-color: black;
}
@media (max-width: 1200px) {
  :global(h1) {
    font-size: 2.5em;
  }
  :global(h1.big) {
    font-size: 3em;
  }
}
@media (max-width: 700px) {
  :global(h1) {
    text-align: center;
    align-self: center;
  }
}
@media (max-width: 600px) {
  :global(h1.big) {
    font-size: 2.5em;
  }
}
@media (max-width: 400px) {
  :global(h1) {
    font-size: 2em;
  }
  :global(h1.big) {
    font-size: 2em;
  }
}

:global(.dark) :global(h1) {
  -webkit-text-stroke-color: white;
}
:global(.dark) :global(h1) :global(b) {
  -webkit-text-stroke: 0px;
  -webkit-text-fill-color: white;
}

:global(section.fullscreen) {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 250;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}
:global(section.fullscreen) > :global(*) {
  flex-grow: 0;
}

:global(ul) {
  border: 1px solid rgba(0, 0, 0, 0.5);
  list-style-type: none;
  padding: 5px;
}
:global(ul) :global(li:not(:last-of-type)) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
:global(ul) :global(li) {
  padding: 10px 15px;
}

:global(body.preview) :global(*) {
  animation: none !important;
}

:global(body.theme-goldshow) {
  background: black;
  color: white;
}
:global(body.theme-goldshow) :global(h1) {
  font-family: "Leyton Bold", sans-serif;
  color: goldenrod;
  -webkit-text-stroke-color: black;
  -webkit-text-fill-color: transparent;
  background: url("/img/theme/goldshow/font_tx.jpg");
  background-size: 50%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  text-rendering: geometricPrecision;
  -webkit-text-stroke-width: 0.5px;
}
:global(body.theme-goldshow) :global(h1) :global(b) {
  -webkit-text-stroke: 0.5px;
}
:global(body.theme-goldshow) :global(h1.big) {
  font-size: 7em;
}
@media (max-width: 600px) {
  :global(body.theme-goldshow) :global(h1.big) {
    font-size: 5em;
  }
}

:global(.dark) :global(ul), :global(.dark) :global(ul) :global(li) {
  border-color: rgba(255, 255, 255, 0.5);
}</style>