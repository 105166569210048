<script>
    import { onDestroy } from "svelte";
    import { lang } from "../routes/LanguageStore.svelte";
    import { acnt } from "../routes/AccentStore.svelte";
    import LangSelector from "../parts/LangSelector.svelte";

    export let navPages = [];
    export let filterNav = "";
    export let credits = "";
    export let gdprReset = false; // if function then shows its execution link in footer
    export let dark_scheme = false;
    let scheme = dark_scheme ? "dark" : "light";

    let language;
    let langUnsub = lang.subscribe(l => {
        language = l.current;
    });

    let accent;
    let acntUnsub = acnt.subscribe(a => accent = a.current);

    onDestroy(()=>{
        langUnsub();
        acntUnsub();
    });

    function expandCopyrightImgs(s) {
        return s.replace(/\@([^\s]*)/g,'<img src="/img/copyright/$1" alt="Logo $1">');
    }

</script>

<footer class={scheme}>
    <span class="copyright">{document.title} &copy; {language === "sk" ? "Všetky práva vyhradené" : "All Rights Reserved"} {new Date().getFullYear()}</span>
    {#if navPages.length}
    <nav>
        {#each navPages as page}
        {#if filterNav === "" || (page.nav && page.nav.display && page.nav.display.includes(filterNav) )}
            <a href={"/" + language + "/" + page.slug} style={"color: " + accent + ";"}>{page.name}</a>
        {/if}
        {/each}
    </nav>
    {/if}
    <span class="author">
        {#if language === "sk"}
            {@html expandCopyrightImgs("Stránku napísal @iairu.svg <a href=\"http://iairu.com\" style=\"color: " + accent + ";\">iairu</a> vďaka @cockpit.svg CockpitCMS a @svelte.svg Svelte")}
        {:else}
            {@html expandCopyrightImgs("Website written by @iairu.svg <a href=\"http://iairu.com\" style=\"color: " + accent + ";\">iairu</a> thanks to @cockpit.svg CockpitCMS and @svelte.svg Svelte")}
        {/if}
    </span>
    <LangSelector dropdown={false} />
    {#if credits}<span class="credits">{credits}</span>{/if}
    {#if typeof gdprReset === "function"}
    <span class="gdpr-reset">
        {#if language === "sk"}
            <button class="button-link" on:click={gdprReset}>Kliknite pre zmenu svojich GDPR preferencií</button>
        {:else}
            <button class="button-link" on:click={gdprReset}>Click to change your GDPR preferences</button>
        {/if}
    </span>
    {:else}
    <div class="gdpr-padding"></div>
    {/if}
</footer>

<style lang="scss" global>:global(footer) {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 100%;
  padding: 25px;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  background: lightgray;
  background: linear-gradient(rgb(231, 231, 231), rgb(245, 245, 245) 50%, white 95%);
  color: darkgray;
}
:global(footer) :global(a) {
  opacity: 0.5;
}
:global(footer) :global(span) {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
:global(footer) :global(span) :global(img) {
  display: inline-flex;
  align-content: center;
  width: 25px;
  height: 25px;
  padding: 0 5px;
  opacity: 0.4;
}
:global(footer) :global(span) :global(a) {
  padding-right: 5px;
}
:global(footer) :global(.credits) {
  font-size: 12px;
}
:global(footer.dark) {
  background: rgb(44, 44, 44);
  background: linear-gradient(rgb(24, 24, 24), rgb(10, 10, 10) 50%, black 95%);
  color: gray;
}
:global(footer.dark) :global(img) {
  filter: invert(1);
}
:global(footer.dark) :global(a) {
  opacity: 1;
}
:global(footer) :global(.gdpr-padding) {
  display: block;
  width: 100%;
  height: 400px;
}</style>