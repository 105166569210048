<script>
    import { onMount } from "svelte"; 
    export let flies = [];
    export let blendMode;
    export let hideOverflow = false;
    export let preview = false;

    let section;
    let overlay;
    let overlayHeight = 0;
    let below;

    function findDiffElmBelow(elm) {
        // Finds a different (based on classes) element below the one in argument
        if (elm && elm.parentElement) {
            let siblings = Array.prototype.slice.call(elm.parentElement.children);
            let elmIdx = siblings.findIndex(sibling=>sibling===elm);
            let elmIdxBelow = elmIdx;
            while (elmIdxBelow < siblings.length && siblings[elmIdxBelow].className === elm.className) {
                elmIdxBelow++;
            }
            return (elmIdxBelow !== elmIdx) ? siblings[elmIdxBelow] : undefined;
        } else { return undefined;}
    }

    function recalcOverlayHeight() {
        if (below) {
            overlayHeight = below.clientHeight;
        }
    }

    onMount(()=>{
        // bind:this available only past mount
        below = findDiffElmBelow(section);
        recalcOverlayHeight();
        window.addEventListener("resize",recalcOverlayHeight);
        return ()=>{ // onDestroy
            window.removeEventListener("resize",recalcOverlayHeight);
        }
    })

    function handleFlyStyle(fly) {
        let style = [];
        if (fly.verticalMargin) {style.push("top: " + fly.verticalMargin + "%;")}
        if (fly.horizontalMargin || fly.alignRight) {
            style.push((fly.alignRight ? "right: " : "left: ") + (fly.horizontalMargin || 0) + "%;")
            }
        if (fly.rotation || fly.scale) {
            style.push(
                "transform: " + 
                (fly.rotation ? "rotate(" + fly.rotation + "deg) " : "") +
                (fly.scale && fly.scale > 0 ? "scale(" + fly.scale + ") " : "")
                + ";"
             );
        }
        if (fly.opacity) {style.push("opacity: " + (fly.opacity / 100) + ";")}
        return style.join(" ");
    }
</script>

{#if flies && flies.length}
<section class="flies" bind:this={section}>
    <div
        class={"overlay" + (preview ? " preview" : "")} 
        bind:this={overlay}
        style={
            (overlayHeight ? "height: " + overlayHeight + "px;" : "") +
            (blendMode && blendMode !== "normal" ? "mix-blend-mode: " + blendMode + ";" : "") +
            (hideOverflow ? "overflow: hidden;" : "")
            }
        >
        {#each flies as fly}
            {#if fly.value}
                <img 
                    class={fly.value.mobile ? "" : "hide_m"}
                    src={fly.value.img && 'https://www.saintdust.com' + fly.value.img.path}
                    style={handleFlyStyle(fly.value)}
                    alt={(fly.value.img && fly.value.img.title) ? fly.value.img.title : "Mucha"}
                    >
            {/if}
        {/each}
    </div>
</section>
{/if}

<style lang="scss" global>:global(section.flies) {
  display: flex;
  width: 100%;
  position: relative;
  height: 0px;
  max-height: 0px;
  overflow: visible;
  pointer-events: none;
}
:global(section.flies) :global(.overlay) {
  position: absolute;
  width: 100%;
  z-index: 150;
  box-sizing: border-box;
  overflow: visible;
  pointer-events: none;
}
:global(section.flies) :global(.overlay.preview:after) {
  content: "MUCHY";
  font-size: 12px;
  padding: 0 5px;
  color: white;
  background: gray;
}
:global(section.flies) :global(img) {
  width: 100%;
  max-width: 10vw;
  position: absolute;
  pointer-events: none;
}
@media (max-width: 1200px) {
  :global(section.flies) :global(img.hide_m) {
    display: none;
  }
}</style>