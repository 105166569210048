<script>
    import { onMount } from "svelte";
    import { handleOffscreenClass } from "../scripts/isOffScreen.svelte";
    // floaters = [ { heading (text), subheading (text), text (html), corner (text), translateX (int), translateY (int) }, ...]
    export let floaters = [];
    export let bg_img = "";
    export let dark_scheme = false;
    let scheme = dark_scheme ? "dark" : "light";

    function handleBoxStyle(box) {
        var css = "";
        if (box.value.translateX || box.value.translateY) {
            css += "transform: translate(" + ((box.value.translateX) ? box.value.translateX + "px" : 0) + ", " + 
                                             ((box.value.translateY) ? box.value.translateY + "px" : 0) + ");";
        }
        return css;
    }

    let section;
    onMount(()=>{
        handleOffscreenClass(section);
    })
</script>

<svelte:window
    on:scroll={handleOffscreenClass(section)}
    on:resize={handleOffscreenClass(section)}
/>

<section class={"floaters " + scheme} bind:this={section} style={(bg_img && bg_img.path) ? "background-image: url('" + bg_img.path + "');" : ""}>
    {#if floaters && floaters.length}
    <div class="wrapper">
    {#each floaters as box}{#if box.value}
        <div class="box" style={handleBoxStyle(box)}>
            {#if box.value.heading}<h2>{box.value.heading}</h2>{/if}
            {#if box.value.subheading}<h3>{box.value.subheading}</h3>{/if}
            {#if box.value.img && box.value.img.path}
                <img class="icon" src={'https://www.saintdust.com' + box.value.img.path} alt={box.value.img.title ? box.value.img.title : "Icon / Ikona"}>
            {/if}
            {#if box.value.text}<p>{@html box.value.text}</p>{/if}
            {#if box.value.corner}<span class="corner">{box.value.corner}</span>{/if}
        </div>
    {/if}{/each}
    </div>
    {/if}
</section>


<style lang="scss" global>:global(section.floaters) {
  display: flex;
  flex-flow: row;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  z-index: 9;
  background-color: white;
  background-size: cover;
  background-position: center;
}
:global(section.floaters) :global(.wrapper) {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  justify-content: space-evenly;
  margin: 50px;
  width: 100%;
  max-width: 1200px;
}
:global(section.floaters.offscreen) :global(.box) {
  animation-name: slideoutright;
  animation-timing-function: ease-in-out;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
}
:global(section.floaters) :global(.box) {
  animation-name: slideinright;
  animation-timing-function: ease-in-out;
  animation-duration: 0.75s;
  display: flex;
  flex-flow: column;
  border: 1px solid lightgray;
  padding: 15px;
  margin: 5px;
  width: 100%;
  max-width: 300px;
  color: black;
  background-color: white;
}
:global(section.floaters) :global(.box) :global(h2) {
  margin: 0;
  text-transform: uppercase;
}
:global(section.floaters) :global(.box) :global(h3) {
  margin: 0;
  color: gray;
}
:global(section.floaters) :global(.box) :global(img) {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: 10px 0 0;
  padding: 30px;
  box-sizing: border-box;
}
:global(section.floaters) :global(.box) :global(p) {
  flex-grow: 1;
}
:global(section.floaters) :global(.box) :global(.corner) {
  display: block;
  width: 100%;
  text-align: right;
}
@media (max-width: 900px) {
  :global(section.floaters) :global(.box) {
    transform: none !important;
  }
}
:global(section.floaters.dark) {
  background-color: black;
}
:global(section.floaters.dark) :global(.box) {
  border: 1px solid rgb(66, 66, 66);
  background-color: black;
  color: white;
}

:global(body.theme-goldshow) :global(.floaters.dark) :global(h2) {
  color: rgb(228, 185, 79);
}</style>    